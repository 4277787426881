<template>
  <div class="securityContainer">
    <van-nav-bar
      :title="title"
      :safe-area-inset-top="true"
      :border="false"
      :fixed="true"
      :placeholder="true"
      v-show="from === 'H5' && is_navbar === 'true'"
      left-arrow
      @click-left="onClickLeft"
    />
    <NavigationBar
      :title="title"
      v-show="from !== 'H5' && is_navbar === 'true'"
    ></NavigationBar>
    <div class="securityBody" :style="{ top: this.offsetTop }">
      <div class="securityTitle">
        <img
          src="../../static/images/securitylogo@2x.png"
          alt=""
          srcset=""
          class="securityTitleImg"
        />
        <div class="securityTitleTextContainer">
          <van-notice-bar
            :scrollable="false"
            color="#00A8FF"
            background="#F3FBFF"
          >
            <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :show-indicators="false"
            >
              <van-swipe-item
                v-for="item in materialStatisticList.type_1"
                :key="item.id"
                >{{ getMoment(item.updated_at) }}成功追回被{{
                  item.mobile
                }}找回的{{ item.game_name
                }}{{ item.order_amount }}元</van-swipe-item
              >
            </van-swipe>
          </van-notice-bar>
          <div class="securityTitleNum" v-show="false">
            <div class="securityTitleNumItem">
              <div class="itemHead">{{ statistic.lawsuit_num }}</div>
              <div class="itemBody">累计立案(件)</div>
            </div>
            <div class="securityTitleNumItem">
              <div class="itemHead">{{ statistic.replevin_money }}</div>
              <div class="itemBody">累计追回(元)</div>
            </div>
            <div class="securityTitleNumItem">
              <div class="itemHead">{{ statistic.claims_money }}</div>
              <div class="itemBody">累计赔付(元)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="securityCenter">
        <img
          src="../../static/images/securityposter@2x.png"
          alt=""
          srcset=""
          class="securityPosterImg"
        />
      </div>
      <div class="securityList">
        <div class="securityListHead">
          <van-tabs
            line-width="56px"
            line-height="2px"
            color="#00A8FF"
            title-active-color="#00A8FF"
            title-inactive-color="#AAAAAA"
            @click="onClick"
          >
            <van-tab
              :title="item.name"
              :name="item.type"
              v-for="item in type_set"
              :key="item.type"
            >
            </van-tab>
          </van-tabs>
          <div class="securityListMore" @click="getMoreList">
            <div class="moreText">更多</div>
            <img
              src="../../static/images/rightBlue@2x.png"
              alt=""
              srcset=""
              class="rightBlueIcon"
            />
          </div>
        </div>
        <div class="securityListCenter" v-show="activeName === 3">
          <CompensateForList
            ref="CompensateForList"
            :list="materialStatisticList.type_3"
          ></CompensateForList>
        </div>
        <div class="securityListCenter" v-show="activeName === 4">
          <RecoveryCase
            ref="RecoveryCase"
            :list="materialStatisticList.type_4"
          ></RecoveryCase>
        </div>
        <div class="securityListCenter" v-show="activeName === 5">
          <LitigationDocuments
            ref="LitigationDocuments"
            :list="materialStatisticList.type_5"
            :is_navbar="is_navbar"
          ></LitigationDocuments>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationBar } from "@/components";
import CompensateForList from "@/pages/Security/components/CompensateForList.vue";
import RecoveryCase from "@/pages/Security/components/RecoveryCase.vue";
import LitigationDocuments from "@/pages/Security/components/LitigationDocuments.vue";
import { getMaterialStatistic } from "@/api/security";
import { gitIosAppleModels } from "@/utils/gitIosAppleModels";
export default {
  name: "Security",
  data() {
    return {
      title: "安全保障",
      activeName: 3,
      materialStatisticList: {},
      statistic: {},
      type_set: [],
      offsetTop: "",
      from: "",
      is_navbar: "true",
    };
  },
  components: {
    NavigationBar,
    CompensateForList,
    RecoveryCase,
    LitigationDocuments,
  },
  mounted() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      this.offsetTop = "1.226667rem";
    } else if (isiOS) {
      let iosSafeArea = this.$route.query.iosSafeArea;
      let ios_6_7_8 = gitIosAppleModels();
      if (ios_6_7_8) {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : "1.226667rem";
      } else {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : 90 / (document.documentElement.clientWidth / 10) + "rem";
      }
    }
    this.requestGetMaterialStatistic();
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    if (this.is_navbar == "false") {
      this.offsetTop = "0rem";
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.path == "/compensateforlist" ||
      to.path == "/recoverycase" ||
      to.path == "/litigationdocuments" ||
      to.path == "/litigationdocumentsdeatil"
    ) {
      if (!from.meta.keepAlive) {
        from.meta.keepAlive = true;
      }
      next();
    } else {
      from.meta.keepAlive = false;
      next();
    }
  },
  methods: {
    onClick(name, title) {
      this.activeName = name;
      if (name === 3) {
        this.$refs.CompensateForList._data.defaultPage =
          this.materialStatisticList.type_3.length === 0 ? true : false;
      } else if (name === 4) {
        this.$refs.RecoveryCase._data.defaultPage =
          this.materialStatisticList.type_4.length === 0 ? true : false;
      } else if (name === 5) {
        this.$refs.LitigationDocuments._data.defaultPage =
          this.materialStatisticList.type_5.length === 0 ? true : false;
      }
    },
    getMoreList() {
      // 跳到对应的列表CompensateForList
      if (this.activeName === 3) {
        this.$router.push({
          name: "CompensateForList",
          query: {
            is_navbar: this.is_navbar,
          },
        });
      } else if (this.activeName === 4) {
        this.$router.push({
          name: "RecoveryCase",
          query: {
            is_navbar: this.is_navbar,
          },
        });
      } else if (this.activeName === 5) {
        this.$router.push({
          name: "LitigationDocuments",
          query: {
            is_navbar: this.is_navbar,
          },
        });
      }
    },
    async requestGetMaterialStatistic() {
      try {
        const result = await getMaterialStatistic();
        if (result.code === 20000 || result.status_code === 1000) {
          this.materialStatisticList = result.data.list;
          this.statistic = result.data.statistic;
          this.type_set = result.data.type_set.reduce((prev, item) => {
            item.type === 3 && item.is_show === 1
              ? prev.push({
                  ...item,
                  name: "找回赔付",
                })
              : item.type === 4 && item.is_show === 1
              ? prev.push({
                  ...item,
                  name: "追讨案例",
                })
              : item.type === 5 && item.is_show === 1
              ? prev.push({
                  ...item,
                  name: "诉讼文书",
                })
              : prev;
            return prev;
          }, []);
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        this.$message.error(`请求失败`);
      }
    },
    getMoment(time) {
      return this.$moment(time).format("MM-DD");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.securityContainer {
  width: 100%;
  height: 100vh;
  .securityBody {
    width: 100%;
    min-height: calc(100vh - 46px);
    background: #f9f9f9;
    position: absolute;
    top: 46px;
    .securityTitle {
      width: 375px;
      height: 246px;
      .securityTitleImg {
        width: 100%;
        height: 100%;
      }
      .securityTitleTextContainer {
        width: 345px;
        // height: 120px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        position: absolute;
        top: 167px;
        left: 50%;
        transform: translateX(-50%);
        .securityTitleNum {
          width: 100%;
          // height: 80px;
          border-radius: 0px 0px 8px 8px;
          padding: 10px 11px 20px 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .securityTitleNumItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .itemHead {
              width: 92px;
              height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #444444;
              line-height: 25px;
              text-align: center;
            }
            .itemBody {
              width: 92px;
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #888888;
              line-height: 17px;
              text-align: center;
            }
          }
        }
      }
    }
    .securityCenter {
      width: 345px;
      height: 287px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.5);
      border-radius: 8px;
      margin: 0 auto;
      // margin-top: 50px;
      margin-top: -30px;
      .securityPosterImg {
        width: 100%;
        height: 100%;
      }
    }
    .securityList {
      width: 345px;
      min-height: 368px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.5);
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 12px;
      .securityListHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        border-radius: 8px 8px 0px 0px;
        padding: 0px 15px;
        height: 37px;
        .securityListMore {
          display: flex;
          justify-content: center;
          align-items: center;
          .moreText {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00a8ff;
            line-height: 17px;
          }
          .rightBlueIcon {
            width: 9px;
            height: 17px;
          }
        }
      }
      .securityListCenter {
        width: 100%;
        padding: 16px 15px 18px 15px;
        box-sizing: border-box;
      }
    }
  }
}
::v-deep .van-notice-bar {
  border-radius: 8px;
  padding: 0px 20px;
}
::v-deep .van-swipe-item {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00a8ff;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 37px;
}
::v-deep .van-tab {
  padding: 0px;
  padding-right: 24px;
}
::v-deep .van-tabs__line {
  left: -12px;
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
