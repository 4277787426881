<template>
  <div class="compensateForList">
    <div v-show="!defaultPage">
      <div v-for="(item, index) in list" :key="item.id">
        <div class="compensateForItem">
          <div class="compensateForItemLeft">
            <img :src="item.pic" alt="" srcset="" class="headImg" />
            <div class="headImg mask">
              {{ item.claim_time_speed }}小时内赔付
            </div>
          </div>
          <div class="compensateForItemRight">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="priceContainer">
              <div class="priceLeft">赔付金额：</div>
              <div class="priceRight">{{ item.claim_amount }}元</div>
            </div>
            <div class="priceDate">{{ getMoment(item.claim_at) }}</div>
          </div>
          <img
            src="../../../static/images/payforIcon.png"
            alt=""
            srcset=""
            class="payforIconImg"
          />
        </div>
        <van-divider v-show="index !== list.length - 1" />
      </div>
    </div>
    <div class="defaultPageContainer" v-show="defaultPage">
      <div class="imgContainer">
        <img
          src="../../../static/images/defaultPage@2x.png"
          alt=""
          class="defaultPageImg"
        />
        <div class="defaultPageText">什么都没有哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
  name: "CompensateForList",
  data() {
    return {
      defaultPage: false,
    };
  },
  mounted() {},
  methods: {
    getMoment(time) {
      return this.$moment(time).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="less" scoped>
.compensateForList {
  .compensateForItem {
    display: flex;
    // justify-content: center;
    position: relative;
    .compensateForItemLeft {
      width: 82px;
      height: 82px;
      border-radius: 4px;
      position: relative;
      .headImg {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
      .mask {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 82px;
        height: 82px;
        background: rgba(0, 0, 0, 0.6);
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 82px;
        text-align: center;
      }
    }
    .compensateForItemRight {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 221px;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        line-height: 20px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .priceContainer {
        display: flex;
        .priceLeft {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          line-height: 17px;
        }
        .priceRight {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f44444;
          line-height: 17px;
        }
      }
      .priceDate {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 17px;
      }
    }
    .payforIconImg {
      width: 49px;
      height: 42px;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .defaultPageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .imgContainer {
      width: 161px;
      height: 160px;
      .defaultPageImg {
        width: 100%;
        height: 100%;
      }
      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
::v-deep .van-divider {
  margin: 12px 0px;
}
</style>
