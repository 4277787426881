<template>
  <div class="RecoveryCase">
    <div v-show="!defaultPage">
      <div
        v-for="(item, index) in list"
        :key="item.id"
        @click="goRecoveryCaseDetail(item)"
      >
        <div class="compensateForItem">
          <div class="compensateForItemLeft">
            <img :src="item.pic" alt="" srcset="" class="headImg" />
          </div>
          <div class="compensateForItemRight">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="priceDate">{{ getMoment(item.publish_at) }}</div>
          </div>
        </div>
        <van-divider v-show="index !== list.length - 1" />
      </div>
    </div>
    <div class="defaultPageContainer" v-show="defaultPage">
      <div class="imgContainer">
        <img
          src="../../../static/images/defaultPage@2x.png"
          alt=""
          class="defaultPageImg"
        />
        <div class="defaultPageText">什么都没有哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
  name: "RecoveryCase",
  data() {
    return {
      defaultPage: false,
    };
  },
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getMoment(time) {
      return this.$moment(time).format("YYYY-MM-DD");
    },
    goRecoveryCaseDetail(item) {
      window.location.href = item.url;
    },
  },
};
</script>

<style lang="less" scoped>
.RecoveryCase {
  .compensateForItem {
    display: flex;
    .compensateForItemLeft {
      width: 82px;
      height: 82px;
      border-radius: 4px;
      .headImg {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .compensateForItemRight {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 221px;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        line-height: 20px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .priceDate {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 17px;
      }
    }
  }
  .defaultPageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .imgContainer {
      width: 161px;
      height: 160px;
      .defaultPageImg {
        width: 100%;
        height: 100%;
      }
      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
